
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { ApiRole } from "@/core/api";
import mixin from "@/mixins";
import store from "@/store";
import { getUserRouteDetailBCN } from "@/core/directive/function/user";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "user-permission",
  components: {},
  setup() {
    const route = useRoute();
    const loading = ref<boolean>(true);
    const selectLoading = ref(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const saveButton = ref<HTMLElement | null>(null);
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();
    const { t } = useI18n();

    const selectData = ref([]);
    const entityOptions = ref([]);

    const formData = ref({
      id: route.params.id,
      roles: [],
      advanced_manage: 0,
      user_type: 0,
      operating_entity: [],
      show_merge_data: 0,
      allow_unallocated_data: 0,
    });

    const rules = ref({});

    const options = ref({
      userTypeOptions: [
        {
          label: "System User",
          value: 0,
        },
        {
          label: "SaaS User",
          value: 1,
        },
      ],
    });

    const isShowUserType = computed(() => {
      let flag = false;
      // if (route.name == "user-management-system-users-permission") {
      //   flag = true;
      // }
      if (store.getters.currentUser.user_type === 0) {
        flag = true;
      }
      return flag;
    });

    const getRolesData = () => {
      ApiRole.getDataSourceRbacRoles()
        .then(({ data }) => {
          if (data.code == 0) {
            selectData.value = data.data;
          } else {
            showServerErrorMsg(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const remoteMethod = (query: string) => {
      if (query) {
        selectLoading.value = true;
        getEntityData(query);
      } else {
        entityOptions.value = [];
      }
    };

    const getEntityData = async (keywork) => {
      const { data } = await ApiRole.getDataSourceEntity({
        search_value: keywork,
      });
      selectLoading.value = false;
      if (data.code == 0) {
        entityOptions.value = data.data;
      }
    };

    const getUserPermissionConfig = () => {
      ApiRole.getUserPermissionConfig({ id: route.params.id })
        .then(({ data }) => {
          if (data.code == 0) {
            entityOptions.value = data.data.entity_options;
            formData.value = { ...formData.value, ...data.data };
          } else {
            showServerErrorMsg(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (saveButton.value) {
            saveButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiRole.saveUserPermissionConfig(formData.value)
            .then(({ data }) => {
              loading.value = false;
              saveButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg(() => {
                  console.log();
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const handleReset = () => {
      formData.value.roles = [];
      formData.value.advanced_manage = 0;
    };

    onMounted(() => {
      getUserRouteDetailBCN(route);
      Promise.all([getRolesData(), getUserPermissionConfig()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    });

    return {
      t,
      loading,
      selectLoading,
      formData,
      rules,
      options,
      formRef,
      saveButton,
      selectData,
      entityOptions,
      isShowUserType,
      remoteMethod,
      submit,
      handleReset,
    };
  },
});
